//import PropTypes from 'prop-types'
import React, { Component } from 'react';
import Logo from "../../assets/home/logo.svg";
import Whatsapp from "../../assets/home/whats.svg";
import Instagram from "../../assets/home/insta.svg";
import Email from "../../assets/home/email.svg";
import EmailInput from "../../assets/home/email_input.svg";

export class Home extends Component {
  static propTypes = {};

  render() {
    return (
      <div className='bg-home1 min-h-screen flex justify-center items-center bg-cover bg-center h-auto px-4 relative'>
        {/* Fondo desenfocado */}
        <div className="absolute inset-0 backdrop-blur-sm bg-white/10 z-0"></div>

        {/* Contenido */}
        <div className="relative z-10 flex flex-col xl:flex-row gap-12 xl:gap-5 py-16">
          {/* Elemento 2 */}
          <div className="flex-1 text-center xl:order-2">
            <p className='font-LDCherries text-8xl xl:text-9xl text-[#7A544B] leading-[5rem]'>
              Launching soon
            </p>
            <p className='font-LDCherries tracking-[0.2em] xl:tracking-[0.3em] text-6xl xl:text-7xl text-[#7A544B] leading-[5rem]'>
              our website
            </p>

            {/* Input de email y botón */}
            <div className="mt-6 flex flex-col justify-center items-center gap-4">
              <div className="flex items-center w-80 xl:w-96 shadow-md rounded-2xl border border-gray-300 focus-within:ring-2 focus-within:ring-[#E37F92] bg-white">
                <img
                  src={EmailInput}
                  alt="Email Icon"
                  className="w-8 h-8 ml-3"
                />
                <input
                  type="email"
                  placeholder="email"
                  className="flex-1 px-4 py-2 rounded-2xl font-LDCherries text-4xl text-[#B88F85] placeholder:text-[#B88F85] focus:outline-none"
                />
              </div>

              {/* Movil */}
              <div className='xl:hidden'>
                <button
                  onClick={() => console.log('Button clicked')}
                  className="w-80 px-6 py-2 rounded-2xl font-LDCherries text-4xl text-white bg-gradient-to-r from-[#FFDEE7] to-[#E37F92] hover:opacity-90 transition-all "
                >
                  Enviar
                </button>
                <p className='font-LDCherries text-4xl text-[#7A544B]'>Te avisaremos cuando estemos listos!</p>
              </div>
              {/* Web */}
              <button
                onClick={() => console.log('Button clicked')}
                className="w-96 h-14 rounded-2xl font-LDCherries text-4xl text-white bg-gradient-to-r from-[#FFDEE7] to-[#E37F92] hover:opacity-90 transition-all hidden xl:flex justify-center items-center"
              >
                te avisaremos cuando estemos listos
              </button>
            </div>

          </div>

          {/* Elemento 1 */}
          <div className="flex-1 w-full max-w-sm xl:max-w-2xl flex flex-col justify-center items-center text-center xl:order-1">
            {/* Imagen centrada */}
            <img src={Logo} alt="Chai Manzana" className="w-9/12 xl:w-8/12" />

            {/* Texto */}
            <p className="font-LDCherries text-5xl xl:text-7xl text-[#E37F92] pt-3">
              mientras tanto contáctanos
            </p>

            {/* Íconos de contacto */}
            <div className="flex w-full max-w-sm xl:max-w-2xl justify-center items-center gap-6 pt-4">
              <a href="https://wa.me/your-phone-number" target="_blank" rel="noopener noreferrer">
                <img src={Whatsapp} alt="WhatsApp" className="w-8 h-auto xl:w-10" />
              </a>
              <a href="https://instagram.com/your-profile" target="_blank" rel="noopener noreferrer">
                <img src={Instagram} alt="Instagram" className="w-8 h-auto xl:w-10" />
              </a>
              <a href="mailto:your-email@example.com">
                <img src={Email} alt="Email" className="w-10 h-auto xl:w-12" />
              </a>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default Home;
